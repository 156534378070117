import React from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/seo";
import { EntriesQueryInterface } from "../../types/entries";
import { LayoutDefault } from "../../layouts/default";
import { EntryLists } from "../../components/entry-lists";
import { GridContainer } from "../../components/grid/content-blocks/container";
import { GridRow } from "../../components/grid/content-blocks/row";
import { BreadCrumbs } from "../../components/breadcrumbs";

export const ArticlePage = (props: EntriesQueryInterface) => {
  const { data } = props;
  const {
    craft: { entries },
  } = data;
  const title = "Articles";

  // const schema: SchemaInterface = {
  //   type: 'BlogPosting',
  //   dateModified: dateUpdated,
  //   datePublished: postDate,
  //   name: title,
  //   headline: title,
  //   author,
  //   uri,
  // }

  return (
    <LayoutDefault>
      <Seo title={title} />
      <GridContainer>
        <GridRow>
          <BreadCrumbs />
          <h1>{title}</h1>
          <EntryLists entries={entries} showSnippet leftAlignContent />
        </GridRow>
      </GridContainer>
    </LayoutDefault>
  );
};

export const query = graphql`
  query ArticlesPageQuery {
    craft {
      entries(section: "articles") {
        ...entryArticleStories
      }
    }
  }
`;

export default ArticlePage;
